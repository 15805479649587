import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig'; // Firebase setup
import './PdfOcr.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import backIcon from '../../assets/back.png';
import eg1 from '../../assets/watermakPdf/orc.png';
import { Document, Packer, Paragraph } from 'docx';

const images = [
  { title: 'Pdf OCR', url: eg1 },
];

const PdfOcr = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [ocrFileUrl, setOcrFileUrl] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [ocrLanguage, setOcrLanguage] = useState('eng'); // Default to English for APYHub
  const [errorMessage, setErrorMessage] = useState(null);
  const [toolPrice, setToolPrice] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [ocrOption, setOcrOption] = useState('apyhub'); // Default to APYHub for text extraction
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  // API Keys and URLs
  const APYHUB_API_URL = 'https://api.apyhub.com/ai/document/extract/read/file';
  const APYHUB_TOKEN = 'APY0NH5qRtdKjBXfvhrwoWjOh8JGc58qZjDB1gmHTen9UHdLFgF1QvffEX9L8YcXCdHiatBTLo9MgK';

  const ILPDF_PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b'; 
  const ILPDF_AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  useEffect(() => {
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'PDF OCR'));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price);
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };
    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setOcrFileUrl(null);
    setErrorMessage(null);
    setIsCopied(false); // Reset copy state when a new file is selected
    setConfirmationMessage('');
  };

  const handleOcrOptionChange = (event) => {
    setOcrOption(event.target.value);
    setOcrLanguage('eng'); // Reset language to default when changing options
  };

  const confirmProcess = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        setErrorMessage('User not logged in. Please log in to continue.');
        return;
      }
  
      const userId = user.uid;
  
      // Fetch user balance from Firestore
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const currentBalance = userData.balance || 0;
  
        if (currentBalance < toolPrice) {
          setErrorMessage(`Insufficient coins in your wallet.`);
          return;
        }
  
        // Proceed to show the confirmation modal if the balance is sufficient
        setIsConfirmationModalOpen(true);
      } else {
        setErrorMessage('User details not found. Please log in again.');
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while checking your balance. Please try again later.');
    }
  };
  

  const handleOcr = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a PDF file for OCR.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);
    setIsConfirmationModalOpen(false);

    try {
      if (ocrOption === 'apyhub') {
        await processWithApyHub();
      } else if (ocrOption === 'ilovepdf') {
        await processWithILovePdf();
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred during OCR processing. Please try again later.');
    } finally {
      setIsProcessing(false);
    }
  };

  const processWithApyHub = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    const response = await fetch(APYHUB_API_URL, {
      method: 'POST',
      headers: {
        'apy-token': APYHUB_TOKEN,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`OCR failed with status ${response.status}`);
    }

    const result = await response.json();
    let extractedText = result.data.apyhub.content || 'No text found';
    extractedText = extractedText.replace(/\\n/g, '\n').replace(/\\'/g, "'");

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({ text: extractedText }),
          ],
        },
      ],
    });

    const docxBlob = await Packer.toBlob(doc);
    const downloadUrl = await uploadDocxToFirebase(docxBlob);
    setOcrFileUrl(downloadUrl);
    setConfirmationMessage(`${toolPrice} coins have been deducted from your wallet.`);
    await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
  };

  const processWithILovePdf = async () => {
    const token = await getILovePdfToken();
    const { server, task } = await startTask(token, 'pdfocr');

    const serverFilename = await uploadFile(server, task, selectedFile, token);
    await processFiles(server, task, serverFilename, selectedFile.name, token);

    // Download and upload the processed file to Firebase
    const processedBlob = await downloadFile(server, task, token);
    const downloadUrl = await uploadBlobToFirebase(processedBlob, selectedFile.name);
    setOcrFileUrl(downloadUrl);

    setConfirmationMessage(`${toolPrice} coins have been deducted from your wallet.`);
  };

  const getILovePdfToken = async () => {
    const response = await fetch(ILPDF_AUTH_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: ILPDF_PUBLIC_KEY })
    });

    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    if (!data.server || !data.task) {
      throw new Error('Failed to retrieve server or task ID from response');
    }

    return data;
  };

  const uploadFile = async (server, task, file, token) => {
    const formData = new FormData();
    formData.append('task', task);
    formData.append('file', file);

    const response = await fetch(`https://${server}/v1/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    });

    const data = await response.json();
    return data.server_filename;
  };

  const processFiles = async (server, task, serverFilename, originalFilename, token) => {
    const response = await fetch(`https://${server}/v1/process`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        task: task,
        tool: 'pdfocr',
        files: [
          {
            server_filename: serverFilename,
            filename: originalFilename
          }
        ]
      })
    });

    if (!response.ok) {
      throw new Error(`Process failed with status ${response.status}`);
    }
  };

  const downloadFile = async (server, task, token) => {
    const response = await fetch(`https://${server}/v1/download/${task}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Download failed with status ${response.status}`);
    }

    const blob = await response.blob();
    return blob;
  };

  const uploadBlobToFirebase = async (blob, originalFilename) => {
    try {
      const fileName = `ocr_ilovepdf_${Date.now()}_${originalFilename}`;
      const storageRef = ref(storage, `ocrDocs/${fileName}`);
      const snapshot = await uploadBytes(storageRef, blob);
      const downloadUrl = await getDownloadURL(snapshot.ref);

      await addDoc(collection(db, 'ocrDocs'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload processed PDF to Firebase.');
    }
  };

  const uploadDocxToFirebase = async (docxBlob) => {
    try {
      const fileName = `ocr_docx_${Date.now()}.docx`;
      const storageRef = ref(storage, `ocrDocs/${fileName}`);
      const snapshot = await uploadBytes(storageRef, docxBlob);
      const downloadUrl = await getDownloadURL(snapshot.ref);

      await addDoc(collection(db, 'ocrDocs'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload DOCX to Firebase.');
    }
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const currentBalance = userData.balance || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        await updateDoc(userDocRef, { balance: newBalance });
      } else {
        throw new Error('Insufficient coins in your wallet.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  // const copyLinkToClipboard = async () => {
  //   if (!ocrFileUrl) return;

  //   try {
  //     await navigator.clipboard.writeText(ocrFileUrl);
  //     setIsCopied(true); // Show "Copied!" when the link is successfully copied
  //   } catch (error) {
  //     setConfirmationMessage('Failed to copy link.');
  //   }
  // };

  const copyLinkToClipboard = async () => {
    if (!ocrFileUrl) return;
  
    try {
      await navigator.clipboard.writeText(ocrFileUrl);
      setIsCopied(true); // Show "Copied!" when the link is successfully copied
  
      // Show confirmation message based on the selected OCR option
      if (ocrOption === 'apyhub') {
        setConfirmationMessage('Extracted PDF text link copied to clipboard. Paste it in any browser to download as docx file.');
      } else if (ocrOption === 'ilovepdf') {
        setConfirmationMessage('Copiable text PDF link copied to clipboard. Paste it in any browser to download pdf.');
      }
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };
  

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
        <div className="back-button">
          <img src={backIcon} alt='back-btn' style={{ width: '25px', height: '25px' }} />
          <button className='back-sub'>Back</button>
        </div>
      </div>

      <div className="ocr-pdf-container">
        <h1>PDF OCR</h1>
        <p>Convert scanned PDF to copiable text or extract text using OCR technology.</p>

        <div className="ocr-language-container">
          <label htmlFor="ocr-option">OCR Option:</label>
          <select id="ocr-option" value={ocrOption} onChange={handleOcrOptionChange}>
            <option value="apyhub">Extract text & download as docx</option>
            <option value="ilovepdf">Copiable text in PDF</option>
          </select>
        </div>

        {ocrOption === 'apyhub' && (
          <div className="ocr-language-container">
            <label htmlFor="ocr-language">OCR Language:</label>
            <select 
              id="ocr-language" 
              value={ocrLanguage} 
              onChange={(e) => setOcrLanguage(e.target.value)}
            >
              <option value="eng">English</option>
              <option value="tam">Tamil</option>
              <option value="tel">Telugu</option>
              <option value="kan">Kannada</option>
              <option value="mal">Malayalam</option>
              <option value="hin">Hindi</option>
            </select>
          </div>
        )}

        {ocrOption === 'ilovepdf' && (
          <div className="language-support-message" style={{ color: 'red', marginTop: '20px' }}>
            Note: Only English language is supported for copiable text in PDF.
          </div>
        )}

        <div className="file-upload-container">
          <input 
            type="file" 
            accept=".pdf"  
            onChange={handleFileSelection} 
            ref={fileInputRef}
            className="file-input"
          />
          <button
            className="file-upload-button"
            onClick={() => fileInputRef.current.click()}
            style={{ display: selectedFile ? 'none' : 'block' }}
          >
            Select PDF file
          </button>
        </div>

        {selectedFile && (
          <div className="pdf-preview-container">
            <div className="pdf-preview">
              <div className="pdf-thumbnail">
                <span>FileName: {selectedFile.name}</span>
                <span>FileSize: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
              </div>
            </div>

            <button 
              className="ocr-button" 
              onClick={confirmProcess}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing...' : 'Start OCR'}
            </button>
          </div>
        )}

        {ocrFileUrl && (
          <div className="share-options">
            <button className="share-button" onClick={copyLinkToClipboard}>
              {isCopied ? 'Copied!' : 'Copy Link'}
            </button>
          </div>
        )}

        {errorMessage && <div className="error-message" style={{ marginTop: '25px' }}>{errorMessage}</div>}
        {confirmationMessage && <div className="confirmation-message" style={{ marginTop: '25px' }}>{confirmationMessage}</div>}

        {isConfirmationModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <p>Are you sure you want to deduct {toolPrice} coins for OCR processing?</p>
              <div className="modal-buttons">
                <button className="modal-confirm" onClick={handleOcr}>Ok</button>
                <button className="modal-cancel" onClick={() => setIsConfirmationModalOpen(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PdfOcr;
