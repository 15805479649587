import React, { useState, useEffect, useRef } from 'react';
import { auth, db, storage } from '../../firebaseConfig'; // Firebase setup with Firestore and Storage
import { doc, getDoc, updateDoc, addDoc, collection } from 'firebase/firestore'; // Firestore imports
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase storage imports
import './CompressImage.css';
import { query, where, getDocs } from 'firebase/firestore'; // Firestore imports
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

const CompressImage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [compressionLevel, setCompressionLevel] = useState('recommended'); // Default compression level
  const [toolPrice, setToolPrice] = useState(null); // Tool price from Firestore
  const [imageDownloadUrl, setImageDownloadUrl] = useState(null); // Compressed image URL
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [confirmationMessage, setConfirmationMessage] = useState(''); // Confirmation message
  const [isCopied, setIsCopied] = useState(false); // Track copy status
  const [isCompressing, setIsCompressing] = useState(false); // Track compressing status
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b';
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  useEffect(() => {
    // Fetch tool price from Firestore (compress image price)
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Compress Image'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0]; // Assuming only one document matches
          setToolPrice(toolDoc.data().price); // Set the tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage(null);
  };

  const handleCompressImage = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select an image file to compress.');
      return;
    }
  
    if (!toolPrice) {
      setErrorMessage('Tool price not available. Please try again later.');
      return;
    }
  
    setIsProcessing(true);
    setErrorMessage(null);
  
    try {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
  
        // Fetch user balance
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const currentBalance = userData.balance || 0;
  
          if (currentBalance < toolPrice) {
            setErrorMessage(`Insufficient coins in your wallet.`);
            setIsProcessing(false);
            return; // Stop the process if balance is insufficient
          }
  
          // Show confirmation modal
          setIsModalOpen(true);
        } else {
          setErrorMessage('User details not found.');
        }
      } else {
        setErrorMessage('User not logged in. Please log in.');
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while checking wallet balance.');
    } finally {
      setIsProcessing(false);
    }
  };
  

  const processAndDeduct = async (userId, price) => {
    try {
      // Set compressing to true when the compression starts
      setIsCompressing(true);

      const token = await getToken();
      const { server, task } = await startTask(token, 'compressimage');
      const serverFilename = await uploadFile(server, task, selectedFile, token);
      await processFile(server, task, serverFilename, selectedFile.name, token);
      const compressedFileBlob = await downloadFile(server, task, token);
      const compressedFileUrl = await uploadCompressedImage(compressedFileBlob); // Upload to Firebase

      setImageDownloadUrl(compressedFileUrl); // Set compressed image URL for sharing

      // Deduct the amount from the user's wallet after successful compression
      await deductAmountFromWallet(userId, price);
      setConfirmationMessage(`${price} Coins have been deducted from your wallet.`);
    } catch (error) {
      console.error('An error occurred during compression: ', error);
      setErrorMessage('Failed to compress the image. Please try again later.');
    } finally {
      setIsCompressing(false); // Reset compressing state
    }
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const currentBalance = userData.balance || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        await updateDoc(userDocRef, { balance: newBalance });
      } else {
        throw new Error('Insufficient coins in your wallet.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const uploadCompressedImage = async (blob) => {
    try {
      if (!(blob instanceof Blob)) {
        throw new Error('Invalid file format. Please try again.');
      }

      const fileName = `compressed_image_${Date.now()}.jpg`;
      const storageRef = ref(storage, `compressedImages/${fileName}`);
      const snapshot = await uploadBytes(storageRef, blob);
      const downloadUrl = await getDownloadURL(snapshot.ref);

      const docRef = await addDoc(collection(db, 'compressedImages'), {
        downloadUrl,
        timestamp: new Date(),
      });

      // console.log('Document written with ID: ', docRef.id);
      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload compressed image to Firebase.');
    }
  };

  const getToken = async () => {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: PUBLIC_KEY }),
    });

    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    return data;
  };

  const uploadFile = async (server, task, file, token) => {
    const formData = new FormData();
    formData.append('task', task);
    formData.append('file', file);

    const response = await fetch(`https://${server}/v1/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    });

    const data = await response.json();
    return data.server_filename;
  };

  const processFile = async (server, task, serverFilename, originalFilename, token) => {
    const requestBody = {
      task: task,
      tool: 'compressimage',
      files: [
        {
          server_filename: serverFilename,
          filename: originalFilename,
        },
      ],
      compression_level: compressionLevel,
    };

    const response = await fetch(`https://${server}/v1/process`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    return data;
  };

  const downloadFile = async (server, task, token) => {
    const response = await fetch(`https://${server}/v1/download/${task}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const blob = await response.blob();
    return blob;
  };

  const copyLinkToClipboard = async () => {
    if (!imageDownloadUrl) {
      setConfirmationMessage('No image Link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(imageDownloadUrl);
      setIsCopied(true);
      setConfirmationMessage('Compressed Image Link copied to clipboard. paste copied link in any browser to download image.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  const handleModalConfirm = async () => {
    setIsModalOpen(false);
    const userId = auth.currentUser.uid;
    await processAndDeduct(userId, toolPrice); // Process the image and deduct after success
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="compress-image-container">
      <h1>Compress Image</h1>
      <p>Compress your JPG images and get the result via a shareable link.</p>

      <div className="file-upload-container">
        <input
          type="file"
          accept='.jpg'
          onChange={handleFileSelection}
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select Image
        </button>
      </div>

      {selectedFile && (
        <div className="compress-settings">
          <label htmlFor="compression-level">Compression Level:</label>
          <select
            id="compression-level"
            value={compressionLevel}
            onChange={(e) => setCompressionLevel(e.target.value)}
          >
            <option value="extreme">Extreme</option>
            <option value="recommended">Recommended</option>
            <option value="low">Low</option>
          </select>

          <button
            className="compress-button"
            onClick={handleCompressImage}
            disabled={isProcessing || isCompressing}
          >
            {isCompressing ? 'Compressing...' : 'Compress and Upload'}
          </button>

          {imageDownloadUrl && (
            <div className="share-options">
              <button className="share-button" onClick={copyLinkToClipboard}>
                {isCopied ? 'Copied!' : 'Copy Link'}
              </button>
            </div>
          )}
        </div>
      )}

      {confirmationMessage && <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>}
      {errorMessage && <div className="error-message" style={{marginTop: '25px'}}>{errorMessage}</div>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} Coins from your wallet?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleModalConfirm}>OK</button>
              <button className="modal-cancel" onClick={handleModalCancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}

    </div>
    </>
  );
};

export default CompressImage;
