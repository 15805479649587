import React, { useState, useRef, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { auth, db, storage } from '../../firebaseConfig'; // Firebase setup
import { doc, getDoc, updateDoc, addDoc, collection, query, where, getDocs } from 'firebase/firestore'; // Firestore imports
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase storage imports
import './CropImage.css'; // CSS file
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

const CropImage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(1); // Default aspect ratio (1:1 for square)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [toolPrice, setToolPrice] = useState(null); // Tool price from Firestore
  const [confirmationMessage, setConfirmationMessage] = useState(''); // Confirmation message
  const [croppedFileUrl, setCroppedFileUrl] = useState(null); // Cropped file URL
  const [isCopied, setIsCopied] = useState(false); // Copy status
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state for confirmation
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b';
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  useEffect(() => {
    // Fetch tool price from Firestore for cropping images
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Crop Image'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0]; // Assuming only one document matches
          setToolPrice(toolDoc.data().price); // Set the tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
      setErrorMessage(null);
      setCroppedFileUrl(null);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropImage = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select an image file to crop.');
      return;
    }
  
    if (!toolPrice) {
      setErrorMessage('Tool price not available. Please try again later.');
      return;
    }
  
    setErrorMessage(null);
  
    try {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
  
        // Fetch user balance
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const currentBalance = userData.balance || 0;
  
          if (currentBalance < toolPrice) {
            setErrorMessage(`Insufficient coins in your wallet.`);
            return; // Stop the process if balance is insufficient
          }
  
          // Show confirmation modal if balance is sufficient
          setIsModalOpen(true);
        } else {
          setErrorMessage('User details not found.');
        }
      } else {
        setErrorMessage('User not logged in. Please log in.');
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while checking wallet balance.');
    }
  };
  

  const confirmCropping = async () => {
    setIsModalOpen(false); // Close modal
    setIsProcessing(true); // Show processing state
    setConfirmationMessage('');
    setErrorMessage(null);

    try {
      const token = await getToken();
      const { server, task } = await startTask(token, 'cropimage');

      const serverFilename = await uploadFile(server, task, selectedFile, token);
      await processFile(server, task, serverFilename, selectedFile.name, token);

      const croppedBlob = await downloadFile(server, task, token);
      const downloadUrl = await uploadCroppedImageToFirebase(croppedBlob);

      setCroppedFileUrl(downloadUrl); // Set the download URL for sharing
      setConfirmationMessage(`${toolPrice} coins have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred during the cropping process.');
    } finally {
      setIsProcessing(false);
    }
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const currentBalance = userData.balance || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        await updateDoc(userDocRef, { balance: newBalance });
      } else {
        throw new Error('Insufficient coins in your wallet.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const uploadCroppedImageToFirebase = async (blob) => {
    try {
      const fileName = `cropped_image_${Date.now()}.png`;
      const storageRef = ref(storage, `croppedImages/${fileName}`);
      const snapshot = await uploadBytes(storageRef, blob);
      const downloadUrl = await getDownloadURL(snapshot.ref);
      await addDoc(collection(db, 'croppedImages'), { downloadUrl, timestamp: new Date() });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload cropped image to Firebase.');
    }
  };

  const getToken = async () => {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: PUBLIC_KEY })
    });
    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const text = await response.text();
    if (!response.ok || !text) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = JSON.parse(text);
    if (!data.server || !data.task) {
      throw new Error('Failed to retrieve server or task ID from response');
    }

    return data;
  };

  const uploadFile = async (server, task, file, token) => {
    const formData = new FormData();
    formData.append('task', task);
    formData.append('file', file);

    const response = await fetch(`https://${server}/v1/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`Upload failed with status ${response.status}`);
    }

    const data = await response.json();
    return data.server_filename;
  };

  const processFile = async (server, task, serverFilename, originalFilename, token) => {
    const requestBody = {
      task: task,
      tool: 'cropimage',
      files: [
        {
          server_filename: serverFilename,
          filename: originalFilename,
        }
      ],
      width: croppedAreaPixels.width,
      height: croppedAreaPixels.height,
      x: croppedAreaPixels.x,
      y: croppedAreaPixels.y,
    };

    const response = await fetch(`https://${server}/v1/process`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Process failed with status ${response.status}: ${errorText}`);
    }

    const data = await response.json();
    return data;
  };

  const downloadFile = async (server, task, token) => {
    const response = await fetch(`https://${server}/v1/download/${task}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Download failed with status ${response.status}`);
    }

    const blob = await response.blob();
    return blob;
  };

  const copyLinkToClipboard = async () => {
    if (!croppedFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(croppedFileUrl);
      setIsCopied(true);
      setConfirmationMessage('Cropped image link copied to clipboard. paste copied link in any browser to download image.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="crop-image-container">
      <h1>Crop Image</h1>
      <p>Select the area to crop by dragging the square over the image.</p>

      <div className="file-upload-container">
        <input 
          type="file"
          accept='.jpg,.png,.jpeg'
          onChange={handleFileSelection} 
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select Image
        </button>
      </div>

      {imageSrc && (
        <div className="crop-container">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspect} 
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
      )}

      {selectedFile && (
        <div className="crop-settings">
          <label>
            Aspect Ratio:
            <select value={aspect} onChange={(e) => setAspect(Number(e.target.value))}>
              <option value={1}>Square (1:1)</option>
              <option value={4/3}>4:3</option>
              <option value={16/9}>16:9</option>
              <option value={3/2}>3:2</option>
              <option value={1.85}>Widescreen (1.85:1)</option>
            </select>
          </label>

          <label>
            Zoom:
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e) => setZoom(Number(e.target.value))}
            />
          </label>

          <button 
            className="crop-button" 
            onClick={handleCropImage}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Crop Image'}
          </button>

          {croppedFileUrl && (
            <div className="share-options">
              <button className="share-button" onClick={copyLinkToClipboard}>
                {isCopied ? 'Copied!' : 'Copy Link'}
              </button>
            </div>
          )}

          
        </div>
      )}

      {confirmationMessage && <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>}

      {errorMessage && <div className="error-message" style={{marginTop: '25px'}}>{errorMessage}</div>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} coins for cropping?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={confirmCropping}>OK</button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default CropImage;
