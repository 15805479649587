import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig'; // Firebase setup
import './ResizeImage.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

const ResizeImage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [resizeMode, setResizeMode] = useState('pixels');
  const [pixelsWidth, setPixelsWidth] = useState('');
  const [pixelsHeight, setPixelsHeight] = useState('');
  const [percentage, setPercentage] = useState('');
  const [maintainRatio, setMaintainRatio] = useState(true);
  const [noEnlargeIfSmaller, setNoEnlargeIfSmaller] = useState(true);
  const [resizeFileUrl, setResizeFileUrl] = useState(null);
  const [toolPrice, setToolPrice] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b';
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  // Fetch tool price from Firestore
  useEffect(() => {
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Resize Image'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price); // Set tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage(null);
    setResizeFileUrl(null); // Reset the resized image URL
    setIsCopied(false); // Reset the copy status
  };

  const confirmProcess = async () => {
    try {
      setIsModalOpen(false); // Close the modal initially
  
      // Fetch user balance
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const currentBalance = userData.balance || 0;
  
        if (currentBalance < toolPrice) {
          setErrorMessage('Insufficient coins in your wallet.');
          return; // Stop the process if the user doesn't have enough balance
        }
  
        setIsModalOpen(true); // Open confirmation modal if sufficient balance
      } else {
        setErrorMessage('User not found.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while checking wallet balance.');
    }
  };

  const handleResizeImage = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select an image file to resize.');
      return;
    }

    if (resizeMode === 'pixels' && (!pixelsWidth || !pixelsHeight)) {
      setErrorMessage('Width and Height are required for pixel resize mode.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);
    setIsModalOpen(false); // Close modal

    try {
      const token = await getToken();
      const { server, task } = await startTask(token, 'resizeimage');

      const serverFilename = await uploadFile(server, task, selectedFile, token);
      const imageBlob = await processFile(server, task, serverFilename, selectedFile.name, token);

      const downloadUrl = await uploadResizedImageToFirebase(imageBlob); // Upload resized image to Firebase

      setResizeFileUrl(downloadUrl); // Set the download URL for display

      setConfirmationMessage(`${toolPrice} coins have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice); // Deduct wallet amount
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while resizing the image. Please try again later.');
    } finally {
      setIsProcessing(false);
    }
  };

  const getToken = async () => {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: PUBLIC_KEY })
    });

    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const text = await response.text();
    if (!response.ok || !text) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = JSON.parse(text);
    return data;
  };

  const uploadFile = async (server, task, file, token) => {
    const formData = new FormData();
    formData.append('task', task);
    formData.append('file', file);

    const response = await fetch(`https://${server}/v1/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`Upload failed with status ${response.status}`);
    }

    const data = await response.json();
    return data.server_filename;
  };

  const processFile = async (server, task, serverFilename, originalFilename, token) => {
    const requestBody = {
      task: task,
      tool: 'resizeimage', // Correct tool name
      files: [
        {
          server_filename: serverFilename,
          filename: originalFilename,
        }
      ],
      resize_mode: resizeMode,
      maintain_ratio: maintainRatio,
      no_enlarge_if_smaller: noEnlargeIfSmaller,
    };

    if (resizeMode === 'pixels') {
      requestBody.pixels_width = parseInt(pixelsWidth, 10);
      requestBody.pixels_height = parseInt(pixelsHeight, 10);
    } else if (resizeMode === 'percentage') {
      requestBody.percentage = parseInt(percentage, 10);
    }

    const response = await fetch(`https://${server}/v1/process`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Process failed with status ${response.status}: ${errorText}`);
    }

    const data = await response.json();
    return await downloadFile(server, task, token); // Get resized image blob
  };

  const downloadFile = async (server, task, token) => {
    const response = await fetch(`https://${server}/v1/download/${task}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Download failed with status ${response.status}`);
    }

    const blob = await response.blob();
    return blob;
  };

  const uploadResizedImageToFirebase = async (imageBlob) => {
    try {
      const fileName = `resized_image_${Date.now()}.jpg`;
      const storageRef = ref(storage, `resizedImages/${fileName}`);
      const snapshot = await uploadBytes(storageRef, imageBlob); // Upload the image blob to Firebase Storage
      const downloadUrl = await getDownloadURL(snapshot.ref);

      // Store download URL in Firestore
      await addDoc(collection(db, 'resizedImages'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload resized image to Firebase.');
    }
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const currentBalance = userData.balance || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        await updateDoc(userDocRef, { balance: newBalance });
      } else {
        throw new Error('Insufficient coins in your wallet.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const copyLinkToClipboard = async () => {
    if (!resizeFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(resizeFileUrl);
      setIsCopied(true);
      setConfirmationMessage('Resized image link copied to clipboard. Paste the copied link in any browser to download the file.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="resize-image-container">
      <h1>Resize Image</h1>
      <p>Resize your images using various modes and settings.</p>

      <div className="file-upload-container">
        <input 
          type="file" 
          accept='.jpg,.jpeg,.png'
          onChange={handleFileSelection} 
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select Image
        </button>
      </div>

      {selectedFile && (
        <div className="image-preview-container" style={{ flexDirection: 'column' }}>
          <div className="pdf-preview">
            <div className='pdf-thumbnail'>
            <span>FileName: {selectedFile.name}</span>
            <span>FileSize: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
            </div>
          </div>

          <div className="resize-settings">
            <label htmlFor="resize-mode">Resize Mode:</label>
            <select 
              id="resize-mode" 
              value={resizeMode} 
              onChange={(e) => setResizeMode(e.target.value)}
            >
              <option value="pixels">Pixels</option>
              <option value="percentage">Percentage</option>
            </select>

            {resizeMode === 'pixels' && (
              <>
                <label>Width (pixels):</label>
                <input 
                  type="number" 
                  placeholder='Enter width'
                  value={pixelsWidth} 
                  onChange={(e) => setPixelsWidth(e.target.value)}
                  required
                />
                <label>Height (pixels):</label>
                <input 
                  type="number" 
                  placeholder='Enter height'
                  value={pixelsHeight} 
                  onChange={(e) => setPixelsHeight(e.target.value)}
                  required
                />
              </>
            )}

            {resizeMode === 'percentage' && (
              <>
                <label>Percentage:</label>
                <input 
                  type="number" 
                  placeholder='Enter percentage'
                  value={percentage} 
                  onChange={(e) => setPercentage(e.target.value)}
                />
              </>
            )}

            <div>
            <label>
              <input
                type="checkbox"
                checked={maintainRatio}
                onChange={(e) => setMaintainRatio(e.target.checked)}
              />
              Maintain Aspect Ratio
            </label>

            <label>
              <input
                type="checkbox"
                checked={noEnlargeIfSmaller}
                onChange={(e) => setNoEnlargeIfSmaller(e.target.checked)}
              />
              Do Not Enlarge if Smaller
            </label>
            </div>

            <button 
              className="resize-button" 
              onClick={confirmProcess}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing...' : 'Resize Image'}
            </button>
          </div>
        </div>
      )}

      {resizeFileUrl && (
        <div className="share-options">
          <button className="share-button" onClick={copyLinkToClipboard}>
            {isCopied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      )}

      {errorMessage && <div className="error-message" style={{marginTop: '25px'}}>{errorMessage}</div>}
      {confirmationMessage && <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} coins for resizing the image?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleResizeImage}>Ok</button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default ResizeImage;
