import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig';
import './WordToPdf.css'; // Reusing the CSS file for similar UI styling
import { saveAs } from 'file-saver';
import * as mammoth from 'mammoth'; // For reading the DOCX files
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

const WordToPdf = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [toolPrice, setToolPrice] = useState(null); // For tool price from Firestore
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [convertedFileUrl, setConvertedFileUrl] = useState(null); // Store converted PDF URL
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const API_KEY = 'APY0x6ALFmgXN4t7nn81S3SdqqsP5FVXmr3Bb5NoFs1H5ddn1QCnLwMUqmY3AKtOulE';
  const WORD_TO_PDF_API_URL = 'https://api.apyhub.com/convert/word-file/pdf-file';

  useEffect(() => {
    // Fetch tool price from Firestore
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Word to PDF'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price); // Set tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  // Estimate page count based on word count (average 300-400 words per page)
  const estimatePageCount = (text) => {
    const wordsPerPage = 1500; // Rough estimate for DOCX
    const wordCount = text.length;
    return Math.ceil(wordCount / wordsPerPage); 
  };

  // Handle file selection
  const handleFileSelection = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const { value: textContent } = await mammoth.extractRawText({ arrayBuffer });

        // Estimate the number of pages
        const estimatedPages = estimatePageCount(textContent);

        if (estimatedPages > 40) {
          setErrorMessage('The document exceeds 40 pages. Please upload a smaller file.');
          setSelectedFile(null);
          setPageCount(null);
        } else {
          setSelectedFile(file);
          setPageCount(estimatedPages);
          setErrorMessage(null);
        }
      } catch (error) {
        setErrorMessage('Error processing the file.');
      }
    } else {
      setErrorMessage('Please upload a valid DOCX file.');
    }
  };

  const confirmProcess = async () => {
    try {
      setIsModalOpen(false); // Close the modal initially
  
      // Fetch user balance
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const currentBalance = userData.balance || 0;
  
        if (currentBalance < toolPrice) {
          setErrorMessage('Insufficient coins in your wallet.');
          return; // Stop the process if the user doesn't have enough balance
        }
  
        setIsModalOpen(true); // Open confirmation modal if sufficient balance
      } else {
        setErrorMessage('User not found.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while checking wallet balance.');
    }
  };

  // Convert Word to PDF
  const handleConvert = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a Word file for conversion.');
      return;
    }

    if (pageCount > 40) {
      setErrorMessage('Cannot convert files larger than 40 pages.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);
    setIsModalOpen(false); // Close modal

    const formData = new FormData();
    formData.append('file', selectedFile);

    const outputFileName = selectedFile.name.replace(/\.[^/.]+$/, '.pdf');

    try {
      const response = await fetch(`${WORD_TO_PDF_API_URL}?output=${outputFileName}`, {
        method: 'POST',
        headers: {
          'apy-token': API_KEY,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Conversion failed with status ${response.status}`);
      }

      const blob = await response.blob();

      // Upload converted PDF to Firebase
      const downloadUrl = await uploadPdfToFirebase(blob, outputFileName);
      setConvertedFileUrl(downloadUrl); // Store the converted file URL

      setConfirmationMessage(`${toolPrice} coins have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred during conversion. Please try again later.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Upload PDF to Firebase
  const uploadPdfToFirebase = async (pdfBlob, fileName) => {
    try {
      const storageRef = ref(storage, `convertedPdfs/${fileName}`);
      const snapshot = await uploadBytes(storageRef, pdfBlob); 
      const downloadUrl = await getDownloadURL(snapshot.ref);

      await addDoc(collection(db, 'convertedPdfs'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload converted PDF to Firebase.');
    }
  };

  // Deduct coins from user's wallet
  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const currentBalance = userData.balance || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        await updateDoc(userDocRef, { balance: newBalance });
      } else {
        throw new Error('Insufficient coins in your wallet.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const copyLinkToClipboard = async () => {
    if (!convertedFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(convertedFileUrl);
      setIsCopied(true);
      setConfirmationMessage('Converted PDF link copied to clipboard.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  // Reset the file input and states
  const resetPage = () => {
    setSelectedFile(null);
    setErrorMessage(null);
    setPageCount(null);
    setIsCopied(false);
    setConvertedFileUrl(null);
    fileInputRef.current.value = '';
  };

  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="translate-pdf-container">
      <h1>Word to PDF Converter</h1>
      <p>Convert your Word (DOCX) files into a PDF document. The file should be less than 40 pages.</p>

      {/* File upload section */}
      <div className="file-upload-container">
        <input
          type="file"
          accept=".docx"
          onChange={handleFileSelection}
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select Docx File
        </button>
      </div>

      {selectedFile && (
        <div className="pdf-preview-container">
          <div className="pdf-thumbnail">
            <span>File Name: {selectedFile.name}</span>
            <span>File Size: {(selectedFile.size / 1024).toFixed(2)} KB</span>
            <span>Estimated Pages: {pageCount}</span>
          </div>
          <button
            className="translate-button"
            onClick={confirmProcess}
            disabled={isProcessing}
          >
            {isProcessing ? 'Converting...' : 'Convert to PDF'}
          </button>
        </div>
      )}

      {convertedFileUrl && (
        <div className="share-options">
          <button className="share-button" onClick={copyLinkToClipboard}>
            {isCopied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      )}

      {errorMessage && <div className="error-message" style={{ marginTop: '20px' }}>{errorMessage}</div>}
      {confirmationMessage && <div className="confirmation-message">{confirmationMessage}</div>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} coins for the Word to PDF conversion?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleConvert}>Confirm</button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default WordToPdf;
