import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig'; // Firebase setup
import './MergePdf.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

const MergePdf = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [mergedFileUrl, setMergedFileUrl] = useState(null);
  const [isMerging, setIsMerging] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(null);
  const [toolPrice, setToolPrice] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b';
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  useEffect(() => {
    // Fetch tool price from Firestore
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Merge PDF'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price); // Set tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFiles([...event.target.files]);
    setMergedFileUrl(null);
    setErrorMessage(null);
    setConfirmationMessage('');
    setIsCopied(false);
  };

  const confirmProcess = async () => {
    try {
      setIsModalOpen(false); // Close the modal initially
  
      // Fetch user balance
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const currentBalance = userData.balance || 0;
  
        if (currentBalance < toolPrice) {
          setErrorMessage('Insufficient coins in your wallet.');
          return; // Stop the process if the user doesn't have enough balance
        }
  
        setIsModalOpen(true); // Open confirmation modal if sufficient balance
      } else {
        setErrorMessage('User not found.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while checking wallet balance.');
    }
  };
  

  const handleMerge = async () => {
    if (selectedFiles.length < 2) {
      setErrorMessage('Please select at least 2 PDF files to merge.');
      return; 
    }

    setIsMerging(true);
    setErrorMessage(null);
    setIsModalOpen(false);

    try {
      const token = await authenticate();
      const { server, task } = await getTaskAndServer(token);

      if (!server || !task) {
        throw new Error('Failed to retrieve server or task ID');
      }

      const serverFilenames = await uploadFiles(server, task, token);

      if (serverFilenames.length < 2) {
        throw new Error('At least 2 files are required for merging.');
      }

      const pdfBlob = await processFiles(server, task, serverFilenames, token);
      const downloadUrl = await uploadPdfToFirebase(pdfBlob);

      setMergedFileUrl(downloadUrl); // Set the download URL for display
      setConfirmationMessage(`${toolPrice} coins have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while merging the PDFs. Please try again later.');
    } finally {
      setIsMerging(false);
      setIsModalOpen(false); // Close modal
    }
  };

  const resetPage = () => {
    setSelectedFiles([]);
    setMergedFileUrl(null);
    setIsMerging(false);
    setErrorMessage(null);
    setConfirmationMessage('');
    setIsCopied(false); // Reset copy status

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const authenticate = async () => {
    try {
      const response = await fetch(AUTH_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ public_key: PUBLIC_KEY })
      });

      if (!response.ok) {
        throw new Error(`Authentication failed with status ${response.status}`);
      }

      const data = await response.json();
      return data.token;
    } catch (error) {
      throw new Error('Authentication error: ' + error.message);
    }
  };

  const getTaskAndServer = async (token) => {
    try {
      const response = await fetch('https://api.ilovepdf.com/v1/start/merge', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to start task: ${response.status} ${errorText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`Request failed: ${error.message}`);
    }
  };

  const uploadFiles = async (server, task, token) => {
    try {
      const uploadedFilenames = [];

      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('task', task);

        const uploadResponse = await fetch(`https://${server}/v1/upload`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData
        });

        if (!uploadResponse.ok) {
          throw new Error(`Upload failed with status ${uploadResponse.status}`);
        }

        const uploadData = await uploadResponse.json();
        uploadedFilenames.push(uploadData.server_filename);
      }

      return uploadedFilenames;
    } catch (error) {
      throw new Error('Upload files error: ' + error.message);
    }
  };

  const processFiles = async (server, task, serverFilenames, token) => {
    try {
      const requestBody = {
        task: task,
        tool: 'merge',
        files: serverFilenames.map((filename, index) => ({
          server_filename: filename,
          filename: selectedFiles[index].name,
        })),
      };

      const processResponse = await fetch(`https://${server}/v1/process`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!processResponse.ok) {
        const errorText = await processResponse.text();
        throw new Error(`Process failed with status ${processResponse.status}`);
      }

      return downloadFile(server, task, token); // Download the merged PDF file as a blob
    } catch (error) {
      throw new Error('Process files error: ' + error.message);
    }
  };

  const downloadFile = async (server, task, token) => {
    try {
      const downloadResponse = await fetch(`https://${server}/v1/download/${task}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!downloadResponse.ok) {
        throw new Error(`Download failed with status ${downloadResponse.status}`);
      }

      const blob = await downloadResponse.blob();
      return blob; // Return the PDF blob
    } catch (error) {
      throw new Error('Download file error: ' + error.message);
    }
  };

  const uploadPdfToFirebase = async (pdfBlob) => {
    try {
      const fileName = `merged_pdf_${Date.now()}.pdf`;
      const storageRef = ref(storage, `mergedPdfs/${fileName}`);
      const snapshot = await uploadBytes(storageRef, pdfBlob); // Upload the PDF blob to Firebase Storage
      const downloadUrl = await getDownloadURL(snapshot.ref); // Get the file's download URL

      // Store the download URL in Firestore
      await addDoc(collection(db, 'mergedPdfs'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload merged PDF to Firebase.');
    }
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const currentBalance = userData.balance || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        await updateDoc(userDocRef, { balance: newBalance });
      } else {
        throw new Error('Insufficient coins in your wallet.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const copyLinkToClipboard = async () => {
    if (!mergedFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(mergedFileUrl);
      setIsCopied(true); // Set to true when the link is copied
      setConfirmationMessage('Merged PDF link copied to clipboard. paste copied link in any browser to download pdf.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="merge-pdf-container">
      <h1>Merge PDF files</h1>
      <p>Combine PDFs with the easiest PDF merger available.</p>

      <div className="file-upload-container">
        <input 
          type="file" 
          accept='.pdf'
          multiple 
          onChange={handleFileSelection} 
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFiles.length > 0 ? 'none' : 'block' }}
        >
          Select PDF files
        </button>
      </div>

      {selectedFiles.length > 0 && (
        <div className="pdf-preview-container">
          {selectedFiles.map((file, index) => (
            <div key={index} className="pdf-preview" style={{margin: 0}}>
              <div className="pdf-thumbnail">
              <span>FileName: {file.name}</span>
              <span>FileSize: {(file.size / 1024 / 1024).toFixed(2)} MB</span>
              </div>
            </div>
          ))}
          <button 
            className="merge-button" 
            onClick={confirmProcess}
            disabled={isMerging}
          >
            {isMerging ? 'Merging...' : 'Merge PDF'}
          </button>
        </div>
      )}

      {mergedFileUrl && (
        <div className="share-options">
          <button className="share-button" onClick={copyLinkToClipboard}>
            {isCopied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      )}

{errorMessage && <div className="error-message" style={{marginTop: '25px'}}>{errorMessage}</div>}
{confirmationMessage && <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} coins for the PDF merging?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleMerge}>Ok</button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      
    </div>
    </>
  );
};

export default MergePdf;
